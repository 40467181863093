<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col
          cols="12"
          class="mt-75"
        >
          <b-alert
            show
            variant="warning"
            class="mb-2"
          >
            <h4 class="alert-heading text-center">
       
<b class="text-danger">Note:</b> Money deposited into the account below will be automatically credited to your wallet.

            </h4>
            
          </b-alert>
        </b-col>
      <b-col cols="12">
        <b-form-group
          label="Bank Name"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-card-text class="text-center ">{{account.bank_name}}</b-card-text>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Account Number"
          label-for="h-email"
          label-cols-md="4"
        >
         <b-card-text class="text-center ">{{account.account_number}}</b-card-text>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Account Name"
          label-for="h-number"
          label-cols-md="4"
        >
           <b-card-text class="text-center  ">{{account.account_name}}</b-card-text>
        </b-form-group>
      </b-col>
     
     

      <!-- submit and reset -->
    
    </b-row>

  </b-form>
</template>

<script>
import {
  BRow, BCol, BAlert, BFormGroup, BCardText, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    props: ['account'],
  components: {
    BRow,
    BAlert,
    BCol,
    BFormGroup,
    BCardText,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
}
</script>
 