<template>
    <div>
<spinner v-if="load" />
<bank-details v-else-if="(load == false) && (bankl == true)" :account="bank" />



                       <validation-observer ref="simpleRules" v-else-if="(load == false) && (error == false) && (show == true)">
                        <b-form @submit.prevent="validationForm()">
         <b-card-text class="text-center text-danger m-2 ">You do not have a wallet account yet, input your bvn below and to generate an account</b-card-text>

             
                <b-form-group label-for="largeInput">
                    <validation-provider
                  #default="{ errors }"
                    name="Bvn"
                    rules="required|digits:11"
            >
                    <b-form-input v-model="bvn"  :state="errors.length > 0 ? false:null" id="largeInput"  size="lg" placeholder="Your Bvn" />
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
          

          <div class="demo-inline-spacing">
          <b-button
           type="submit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
          >
            Generate Account Details
          </b-button>
        </div>
</b-form>
                        </validation-observer>

    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BCardText, BForm, BButton, BFormInput, BFormGroup, } from 'bootstrap-vue'
import {
  required,  digits,
} from '@validations'
import Ripple from 'vue-ripple-directive'

import useServices from "@/auth/jwt/userServices"
import Spinner from '../components/Spinner.vue'
import BankDetails from '../components/BankDetails.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
    BForm,
       ValidationProvider,
       ValidationObserver,
        BFormInput,
        BFormGroup,
        ToastificationContent,
        BankDetails,
        Spinner,
        BButton,
     
        BCardText,
    
  
    },


    data() {
    return {
      bvn: '',
      bankl: false,
      load: false,
      show: false,
      error: false,
      bank: {},
      required,
     digits,
    }
    },
       directives: {
        Ripple,
    },
    created() {
        this.getbankaccount()
    },

     methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.generatebank()
        }
      })
    },




   async getbankaccount(){
     this.load = true;
    try {
        await useServices.getbankaccount().then((result)=> {

            if (result.data.status == true) {
                  console.log(result.data)
                this.bank = result.data.data
                this.bankl = true;
            }else {
             
             this.show = true;



            }
          
            });

          this.load = false;
    } catch (error) {
          this.load = false;
    }

    this.load = false;





    }
,




    async generatebank() {

 this.load = true;
    try {
        await useServices.generatebankaccount({bvn: this.bvn}).then((result)=> {

            if (result.data.status == true) {
                  console.log(result.data)
                this.bank = result.data.data.bank
                this.bankl = true;
                this.$swal({
        title: result.data.data.message,
       
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
            }else {
             
             this.show = true;



            }
          
            });

          this.load = false;
    } catch (error) {
          this.$swal({
        title: 'Something Went Wrong, Please Try again Later',
       
        icon: 'danger',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }

    this.load = false;









    }

     }

    
}
</script>