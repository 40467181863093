<template>
    <div>



      
                       <validation-observer ref="simpleRules">
                        <b-form @submit.prevent="validationForm()">


                          <b-alert
            show
            variant="warning"
            class="mb-2"
          >
            <h4 class="alert-heading text-center">
       
<b class="text-danger">Note:</b> Money deposited  will be automatically credited to your wallet.

            </h4>
            
          </b-alert>
         <b-card-text class="text-center text-success m-2 ">Add an amount You Wish To Fund</b-card-text>

             
                <b-form-group label-for="largeInput">
                    <validation-provider
                  #default="{ errors }"
                    name="Amount"
                    rules="required|between:500,50000"
            >


                  <b-input-group
      prepend="₦"
      append=".00"
    >
      <b-form-input v-model="amount" placeholder="Please add amount" size="lg"/>
    </b-input-group>
                
                
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
          <div class="demo-inline-spacing">
         
         
        </div>  
        <b-row>
<b-col md="6">
<!-- <vue-monnify
        ref="vueMonnifyButton"
        :amount="amount"
        :api-key="monnifyAPIKey"
      
        :contract-code="monnifyContractCode"
        :customer-email="email"
        :is-test-mode="monnifyIsTest"
        btn-class="btn btn-primary btn-block mb-1 mt-2"
        :metadata="payMetadata"
        :on-close="monnifyClose"
        :on-complete="monnifyComplete"
        :reference="payRef"
        :payment-description="'Payment reference'"
    >
 
Pay with Monnify
    </vue-monnify> -->

     <b-button type="submit"  class="btn btn-primary btn-block mb-1 mt-2" @click="getmonnifylink" block variant="primary" :disabled="process">
                              <b-spinner v-if="process"
          small
          type="grow"
        />  
            <span v-else> Pay with Monnify</span> 
                            </b-button>
</b-col>


<b-col md="6">

  <paystack
        :amount="amount * 100"
        :email="email"
        :paystackkey="paystackkey"
        class="btn btn-primary btn-block mb-1 mt-2"
        :reference="payRef"
        :channels="channel"
        :paymentMethods='paymentMethods'
        :callback="callback"
        :close="close"
        :embed="false"
    >
       <i class="fas fa-money-bill-alt"></i>
       Pay with Paystack
    </paystack>


</b-col>
          
        </b-row>

       
    

</b-form>
  </validation-observer>
 
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BCardText, BAlert, BForm, BRow, BCol, BInputGroup, BButton, BFormInput, BFormGroup, } from 'bootstrap-vue'
import {
  required,  between,
} from '@validations'
import paystack from 'vue-paystack';
import useServices from "@/auth/jwt/userServices"
import Loading from 'vue-loading-overlay';
import VueMonnify from 'vue-monnify';
export default {
    components: {
    BForm,
    BCol,
    BAlert,
    BInputGroup,
      VueMonnify,
      paystack,
      Loading,
BRow,
       ValidationProvider,
       ValidationObserver,
        BFormInput,
        BFormGroup,
        BButton,
     
        BCardText,
    
  
    },


    data() {
    return {
      process: false,
      paystackkey: "pk_live_2fff307825723812a570f7efd8f1a0214e0f53e0", //paystack public key
        
        channel: ['card', 'bank', 'ussd', 'qr', 'mobile_money', 'bank_transfer'],
         paymentMethods: ["CARD", "ACCOUNT_TRANSFER"],
            

            monnifyAPIKey: "MK_PROD_WF9GXH8B2W", // This has both test and product values
            monnifyContractCode: "255214993264", // This has both test and product values
            monnifyIsTest: false, // This should be true in test/dev environment and false in production
            payMetadata: {}, // This can be populated with key/values as you wish
            email: this.$store.getters['getuser'].email,
            user : this.$store.getters['getuser'], // Customer email
            amount: 500 ,// of 
    //   amount: '',
      required,
     between,
    }
    },
  

    watch: {
        user: function(val) {

            if(val != null) {


                this.email = val.email;
            }
        }
    },

     methods: {
callback: function(response){

console.log(response);
  if(response.status == 'success'){

     this.verifypayment('paystack', response.reference)



  }

    
      },




    async getmonnifylink() {
       let loader = this.$loading.show();
            await useServices. getmonnifylink({ params: { amount: this.amount } }).then((result)=> {
              loader.hide();
             if (result.data.status == true) {
              window.location.href = result.data.data
             }
             loader.hide();
            });

   loader.hide();

        },

    async verifypayment(type,refcode){


 let loader = this.$loading.show();

                

 await useServices.verifydeposit({
           type: type,
           paymentref: refcode,

         }).then((result)=> {

loader.hide();
           if (result.data.status == true) {
               this.$swal({
        title: result.data.data,
       
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
           } else if(result.data.status == false) {
              this.$swal({
        title: result.data.data,
       
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
           }

           console.log(result)

         });



loader.hide();





    },
      close: function(){
          console.log("Payment closed")
      },
         userdetails() {
var user =  localStorage.getItem("userData");
this.user = JSON.parse(user);


         },
         monnifyComplete: function(response){
          // Perform other operations upon complete
          console.log("Monnify Payment Complete", response)

          if (response.paymentStatus == "PAID") {
             this.verifypayment('monnify', response.transactionReference)
            
          }
        },
        monnifyClose: function(data){
          // Perform other operations upon close
          console.log("Monnify Payment closed", data)
        },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
         
        }
      })
    },

     },
          computed: {
        payRef(){
          let _ref = "";
          const alphanumeric = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

          for( let i=0; i < 10; i++ )
            _ref += alphanumeric.charAt(Math.floor(Math.random() * alphanumeric.length));

          return _ref;
        }
      },

    
}
</script>